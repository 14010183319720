import React, { useEffect, useState, useRef } from 'react'
import { Platform, Dimensions } from 'react-native'
import {
  Box,
  Text,
  Button,
  Divider,
  TextArea,
  ScrollView,
  useToken,
  Pressable,
  Menu
} from 'native-base'
import { Message } from '../types'
import Header, { HeaderTitle, HeaderPressable } from './Header'
import Icon from './Icon'
import useLocale from '../context/locale'

type ChatProps = {
  messages: Message[]
  canSendMessage: boolean
  onRequestClose?: () => void
  onSendMessage: (message: string) => void
  onClearMessages: () => void
}

const Chat = ({
  messages = [],
  onSendMessage,
  canSendMessage,
  onRequestClose,
  onClearMessages
}: ChatProps) => {
  const locale = useLocale()
  const scrollViewRef = useRef<typeof ScrollView>()
  const [message, setMessage] = useState<string>()
  const [touchBreakpoint] = useToken('breakpoints', ['md'])

  // useEffect(() => {
  //   if (
  //     Platform.OS === 'web' &&
  //     Dimensions.get('window').width > touchBreakpoint
  //   ) {
  //     //@ts-ignore
  //     ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  //   }
  // }, [])

  function handleSendMessage() {
    if (message) {
      onSendMessage(message!)
      setMessage('')
    }
  }

  return (
    <Box flex={1} bg="white">
      <Box display={['flex', 'flex', 'none']}>
        <Header>
          <HeaderPressable onPress={onRequestClose}>
            <Icon icon={['far', 'arrow-left']} size={22} />
          </HeaderPressable>
          <HeaderTitle>Chat</HeaderTitle>
          <Box ml="auto">
            <ChatMenu onClearMessages={onClearMessages} />
          </Box>
        </Header>
      </Box>

      {/* {Platform.OS === 'web' && (
        <Box display={['none', 'none', 'flex']}>
          <Box m={5} height="300px">
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6538434310321045"
              data-ad-slot="5858485316"
              data-ad-format="auto"
              data-full-width-responsive="true"
            />
          </Box>
          <Divider />
        </Box>
      )} */}

      {messages.length > 0 && (
        <Box flex={1}>
          <Box
            display={['none', 'none', 'flex']}
            position="absolute"
            zIndex={1}
            right={4}
            top={4}
          >
            <ChatMenu onClearMessages={onClearMessages} />
          </Box>
          <ScrollView
            my={3}
            mx={5}
            ref={scrollViewRef}
            position="relative"
            flexShrink="1"
            flexGrow="1"
            flexBasis="1"
            onContentSizeChange={() => {
              if (scrollViewRef.current) {
                // @ts-ignore NativeBase doesn't have scrollToEnd available on types
                scrollViewRef.current.scrollToEnd({ animated: false })
              }
            }}
          >
            {messages.map((message, index) => (
              <Box key={index} flexDirection="row">
                <Text>
                  <Text fontWeight="bold">
                    {message.fromClient ? 'Me' : message.user}
                  </Text>
                  : {message.message}
                </Text>
              </Box>
            ))}
          </ScrollView>
        </Box>
      )}

      {messages.length == 0 && (
        <Box
          flexShrink="1"
          flexGrow="1"
          flexBasis="1"
          alignItems="center"
          justifyContent="center"
        >
          <Text color="gray.400" fontWeight="bold">
            {locale.t('chat.empty')}
          </Text>
        </Box>
      )}

      <Divider />
      <Box bg="white" p={5} flexDirection="row">
        <TextArea
          mr={5}
          flex={1}
          minH="60px"
          maxH="60px"
          value={message}
          onChangeText={setMessage}
          isDisabled={!canSendMessage}
        />
        <Button isDisabled={!canSendMessage} onPress={handleSendMessage}>
          {locale.t('send')}
        </Button>
      </Box>
    </Box>
  )
}

function ChatMenu({ onClearMessages }: { onClearMessages: () => void }) {
  const locale = useLocale()
  return (
    <Menu
      w="170"
      closeOnSelect={true}
      trigger={(triggerProps) => (
        <Pressable {...triggerProps} bg="white">
          <Box display={['flex', 'flex', 'none']}>
            <Icon icon={['far', 'ellipsis-v']} size={24} />
          </Box>
          <Box display={['none', 'none', 'flex']}>
            <Icon icon={['far', 'ellipsis-v']} size={20} />
          </Box>
        </Pressable>
      )}
    >
      <Menu.Item onPress={onClearMessages}>{locale.t('chat.clear')}</Menu.Item>
    </Menu>
  )
}

export default Chat
