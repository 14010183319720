import React, { useContext } from 'react'
import { useStore } from '../store'

function format(string: string, args: any) {
  return string.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match
  })
}

function locale(lang: string, phrases: any, phrase: string, params: any = []) {
  const text = phrases[lang] && phrases[lang][phrase]
  return text ? (params.length ? format(text, params) : text) : phrase
}

export const Context = React.createContext<any>(null)

export function LocaleProvider({
  phrases,
  children
}: {
  phrases: any
  children: React.ReactNode
}) {
  const { state } = useStore()

  function t(phrase: string, params: any) {
    return locale(state.settings.language, phrases, phrase, params)
  }

  const value = { t }
  //@ts-ignore
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default function useLocale() {
  return useContext(Context)
}
