import store from '../../utils/store'
import { Message, State } from '../../types'
import * as constants from './constants'

export function initialize(data: Partial<State>) {
  return { type: constants.INITIALIZE, payload: data }
}

export async function changeLanguage(language: string) {
  await store.set('language', language)
  return { type: constants.CHANGE_LANGUAGE, payload: language }
}

export async function savePreferences(
  newPreferences: Partial<State['preferences']>
) {
  const preferences = {
    ...(await store.get('preferences', {})),
    ...newPreferences
  }
  await store.set('preferences', preferences)
  return { type: constants.SAVE_PREFERENCES, payload: { preferences } }
}

export async function receiveMessage(message: Message) {
  if (message.fromClient) return {}
  const messages = await store.get('messages', [])
  messages.push(message)
  await store.set('messages', messages)
  await store.set('unreadMessages', true)
  return {
    type: constants.RECEIVE_MESSAGE,
    payload: { messages, unreadMessages: true }
  }
}

export async function sendMessage(message: Message) {
  const messages = await store.get('messages', [])
  messages.push(message)
  await store.set('messages', messages)
  return {
    type: constants.RECEIVE_MESSAGE,
    payload: { messages }
  }
}

export async function readMessages() {
  await store.set('unreadMessages', false)
  return { type: constants.READ_MESSAGES }
}

export function clearMessages() {
  store.set('messages', [])
  return {
    type: constants.CLEAR_MESSAGES
  }
}
