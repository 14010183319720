export const PHONE_STATUSES = {
  IDLE: 'IDLE',
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
  CALL_ESTABLISHED: 'CALL_ESTABLISHED',
  RECONNECTING: 'RECONNECTING',
  HUNG_UP: 'HUNG_UP'
} as const

export const PHONE_ERRORS = {
  NONE: 'NONE',
  PERMISSIONS: 'PERMISSIONS',
  PARTNER_NOT_FOUND: 'PARTNER_NOT_FOUND'
} as const
