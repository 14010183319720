import React, { useEffect, useRef } from 'react'
import Icon from './Icon'
import Timer from './Timer'
import {
  Box,
  Heading,
  FormControl,
  Select,
  Stack,
  Button,
  Text,
  Input,
  IBoxProps
} from 'native-base'
import { AudioElement } from '../utils/peer'
import { useForm, Controller } from 'react-hook-form'
import { State } from '../types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Platform, Pressable } from 'react-native'
import { usePhone } from '../context/phone'
import { CALL_STATUSES } from '../app.constants'
import { PHONE_ERRORS } from '../context/phone/constants'
import useLocale from '../context/locale'

const callStatusesData = {
  [CALL_STATUSES.CONNECTED]: {
    text: 'phone.status.connected',
    color: 'green.500'
  },
  [CALL_STATUSES.END]: {
    text: 'phone.status.callEnded',
    color: 'indigo.500'
  }
}

const ButtonRow = ({ children }: { children: React.ReactNode }) => (
  <Box
    my={8}
    mx={-2}
    height="130px"
    alignItems="center"
    flexDirection="row"
    justifyContent="space-around"
  >
    {children}
  </Box>
)

type PhoneButtonProps = {
  onPress: () => void
  colorScheme?: string
  icon: IconProp
  isPrimary?: boolean
  isDisabled?: boolean
  children: React.ReactNode
} & IBoxProps

const PhoneButton = ({
  onPress,
  colorScheme = 'gray',
  icon,
  isPrimary = false,
  isDisabled = false,
  children,
  ...rest
}: PhoneButtonProps) => {
  return (
    <Box alignItems="center" {...rest}>
      <Button
        isDisabled={isDisabled}
        variant="solid"
        borderRadius="999"
        onPress={onPress}
        colorScheme={colorScheme}
        background={isDisabled ? 'gray.200' : null}
        width={isPrimary ? '86px' : '64px'}
        height={isPrimary ? '86px' : '64px'}
      >
        <Icon icon={icon} color="white" size={isPrimary ? 32 : 21} />
      </Button>
      <Text
        mt={4}
        textAlign="center"
        fontSize={isPrimary ? '14px' : '12px'}
        color="gray.600"
      >
        {children}
      </Text>
    </Box>
  )
}

type CommonButtonProps = {
  onPress: () => void
  isPrimary?: boolean
  isDisabled?: boolean
  isActive?: boolean
}

const CloseButton = ({ isActive, ...rest }: CommonButtonProps) => {
  const locale = useLocale()
  return (
    <PhoneButton
      {...rest}
      icon={['far', 'times']}
      colorScheme="gray"
      isPrimary={true}
    >
      {locale.t('phone.closeButton')}
    </PhoneButton>
  )
}

const SpeakerButton = ({ isActive, ...rest }: CommonButtonProps) => {
  const locale = useLocale()

  return (
    <PhoneButton
      {...rest}
      icon={['fas', Platform.OS === 'web' ? 'volume-slash' : 'volume']}
      colorScheme={isActive ? 'primary' : 'gray'}
    >
      {Platform.OS === 'web'
        ? locale.t('phone.audioButton')
        : locale.t('phone.speakerButton')}
    </PhoneButton>
  )
}

const MuteButton = ({ isActive, ...rest }: CommonButtonProps) => {
  const locale = useLocale()

  return (
    <PhoneButton
      {...rest}
      icon={['fas', 'microphone-slash']}
      colorScheme={isActive ? 'primary' : 'gray'}
    >
      {Platform.OS === 'web'
        ? locale.t('phone.microphoneButton')
        : locale.t('phone.muteButton')}
    </PhoneButton>
  )
}

const NextButton = ({ isActive, ...rest }: CommonButtonProps) => {
  const locale = useLocale()
  return (
    <PhoneButton {...rest} icon={['fas', 'random']}>
      {locale.t('phone.nextButton')}
    </PhoneButton>
  )
}

const CancelButton = ({ ...rest }: CommonButtonProps) => {
  const locale = useLocale()
  return (
    <PhoneButton {...rest} icon={['fas', 'times']}>
      {locale.t('phone.cancel')}
    </PhoneButton>
  )
}

const CallButton = ({ isActive, ...rest }: CommonButtonProps) => {
  const locale = useLocale()
  return (
    <PhoneButton
      {...rest}
      icon={['fas', 'phone']}
      colorScheme={isActive ? 'red' : 'primary'}
    >
      {isActive ? locale.t('phone.hangUpButton') : locale.t('phone.callButton')}
    </PhoneButton>
  )
}

type CallStatusPRops = {
  status?: keyof typeof CALL_STATUSES
  time?: number
  isTimeFixed?: boolean
  onTimeUpdate?: (value: number) => void
  onTimeComplete?: () => void
  title?: string
  gender?: string
  countdown?: boolean
}

const CallStatus = ({
  status,
  time,
  isTimeFixed,
  onTimeUpdate,
  onTimeComplete,
  title,
  gender,
  countdown
}: CallStatusPRops) => {
  const locale = useLocale()
  return (
    <>
      <Box
        mb={12}
        height="30px"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Heading size="md" textAlign="center">
          {title}
        </Heading>
        {/* {gender && (
          <Box py={1} px={2} bg="black" borderRadius="full" ml={2}>
            <Icon
              icon={['far', gender === 'female' ? 'venus' : 'mars']}
              color="white"
              size={14}
            />
          </Box>
        )} */}
      </Box>
      <Box height="80px">
        {time! > -1 && (
          <Timer
            time={time}
            isFixed={isTimeFixed}
            onUpdate={onTimeUpdate}
            countdown={countdown}
            onComplete={onTimeComplete}
          />
        )}
      </Box>

      <Box height="26px">
        {status && (
          <Text
            fontSize="md"
            fontWeight="bold"
            color={callStatusesData[status].color}
            textAlign="center"
          >
            {locale.t(callStatusesData[status].text)}
          </Text>
        )}
      </Box>
    </>
  )
}

type PhoneProps = {
  preferences: State['preferences']
  onCall: (preferences: State['preferences']) => void
  onCallNext: () => void
}

const Phone = ({ preferences, onCall, onCallNext }: PhoneProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<State['preferences']>({
    defaultValues: {
      nickname: preferences.nickname,
      language: preferences.language
    }
  })
  const locale = useLocale()
  const phone = usePhone()
  const audioRef = useRef<HTMLAudioElement>(null)
  const isIdle = phone.status === 'IDLE'
  const isConnecting = phone.status === 'CONNECTING'
  const isConnected = phone.status === 'CONNECTED'
  const isCallEstablished = phone.status === 'CALL_ESTABLISHED'
  const isHungUp = phone.status === 'HUNG_UP'

  useEffect(() => {
    phone.setAudioElement(audioRef.current!)
  }, [audioRef.current])

  return (
    <Box flex={1}>
      {/* <Heading size="md" mb={5} textAlign="center">
        Practica idiomas en llamadas de voz con personas de todo el mundo
      </Heading> */}
      <Box flex={1} justifyContent="center">
        {isIdle && (
          <>
            <Heading size="md" mb={5} textAlign="center">
              {locale.t('phone.findPartner')}
            </Heading>

            <Stack space="4" justifyContent="center" alignItems="center">
              <Controller
                name="nickname"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    isRequired
                    isInvalid={!!errors.nickname}
                    width="250px"
                  >
                    <Input
                      placeholder={locale.t('nickname')}
                      width="100%"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                    {errors.nickname && (
                      <FormControl.ErrorMessage>
                        {locale.t('home.hero.nickname.error')}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name="language"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    isRequired
                    width="250px"
                    isInvalid={!!errors.language}
                  >
                    <Select
                      bg="white"
                      selectedValue={value}
                      onValueChange={onChange}
                      accessibilityLabel={locale.t('language')}
                      placeholder={locale.t('language')}
                    >
                      <Select.Item
                        label={locale.t('english')}
                        value="english"
                      />
                      <Select.Item
                        label={locale.t('spanish')}
                        value="spanish"
                      />
                    </Select>
                    {errors.language && (
                      <FormControl.ErrorMessage>
                        {locale.t('phone.language.error')}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
              />
            </Stack>
            {phone.error === PHONE_ERRORS.PERMISSIONS && (
              <Box mt={4} bg="red.50" py={3} px={4} borderRadius="6px">
                <Text textAlign="center">
                  {locale.t('phone.error.permissions')}{' '}
                  <Pressable>
                    <Text color="primary.500" fontWeight="bold">
                      {locale.t('learnMore')}
                    </Text>
                  </Pressable>
                </Text>
              </Box>
            )}
            {phone.error === PHONE_ERRORS.PARTNER_NOT_FOUND && (
              <Box mt={4} bg="red.50" py={3} px={4} borderRadius="6px">
                <Text textAlign="center">
                  {locale.t('phone.error.partnerNotFound')}
                </Text>
              </Box>
            )}
          </>
        )}

        {isConnecting && <CallStatus title={locale.t('phone.connecting')} />}

        {isConnected && (
          <CallStatus
            time={120}
            title={locale.t('phone.findingPartner')}
            countdown={true}
            onTimeComplete={phone.partnertNotFound}
          />
        )}

        {isCallEstablished && (
          <CallStatus
            time={0}
            title={phone.partner?.nickname}
            status={CALL_STATUSES.CONNECTED}
            gender={phone.partner?.gender}
            onTimeUpdate={phone.updateCallLength}
          />
        )}

        {isHungUp && (
          <CallStatus
            title={phone.partner?.nickname}
            time={phone.callLength}
            isTimeFixed={true}
            status={CALL_STATUSES.END}
            gender="male"
          />
        )}
      </Box>

      <ButtonRow>
        {!isIdle && !isHungUp && (
          <>
            <SpeakerButton
              isActive={phone.isSpeakerMute}
              onPress={phone.toggleSpeaker}
              isDisabled={!isCallEstablished}
            />
            <MuteButton
              isActive={phone.isMicrophoneMute}
              onPress={phone.toggleMicrophone}
              isDisabled={!isCallEstablished}
            />
            <NextButton onPress={onCallNext} isDisabled={!isCallEstablished} />
          </>
        )}

        {(isConnecting || isConnected) && (
          <CancelButton onPress={phone.endCall} />
        )}

        {(isIdle || isCallEstablished) && (
          <CallButton
            isPrimary={isIdle}
            onPress={isIdle ? handleSubmit(onCall) : phone.endCall}
            isActive={isConnecting || isConnected || isCallEstablished}
          />
        )}

        {isHungUp && <CloseButton onPress={phone.reset} />}
      </ButtonRow>

      {Platform.OS === 'web' && <AudioElement ref={audioRef} />}
      {phone.remoteStream && Platform.OS !== 'web' && (
        //@ts-ignore
        <AudioElement streamURL={phone.remoteStream.toURL()} />
      )}
    </Box>
  )
}

export default Phone
