import React, { useEffect, useState } from 'react'
import { Text, Box } from 'native-base'

const Timer = ({
  isFixed = false,
  countdown = false,
  time = 0,
  onUpdate,
  onComplete
}: {
  isFixed?: boolean
  countdown?: boolean
  time?: number
  onUpdate?: (value: number) => void
  onComplete?: () => void
}) => {
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(time)

  useEffect(() => {
    if (onUpdate) {
      onUpdate(elapsedSeconds)
    }

    if (countdown && elapsedSeconds === 0) {
      clearInterval(elapsedSeconds)
      if (onComplete) {
        onComplete()
      }
    }
  }, [elapsedSeconds])

  useEffect(() => {
    if (!isFixed) {
      let interval = setInterval(() => {
        setElapsedSeconds((value: number) => {
          return countdown ? value - 1 : value + 1
        })
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [])

  return (
    <Text textAlign="center" fontSize="48px">
      {new Date(elapsedSeconds * 1000).toISOString().substr(11, 8)}
    </Text>
  )
}
export default Timer
