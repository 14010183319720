import React from 'react'
import { Platform } from 'react-native'
import { Box } from 'native-base'
import * as actions from '../context/store/actions'
import { useStore } from '../context/store'
import { usePhone } from '../context/phone'
import Heading from '../components/Heading'
import Navigator from '../components/Navigator'
import Container from '../components/Container'
import Phone from '../components/Phone'
import Chat from '../components/Chat'
import Loader from '../components/Loader'
import { State } from '../types'
import useAds from '../hooks/useAds'
import useLocale from '../context/locale'
import { PHONE_STATUSES } from '../context/phone/constants'

export default function Call() {
  const phone = usePhone()
  const locale = useLocale()
  const { state, dispatch } = useStore()
  const { showingAd, displayInterstitial } = useAds()

  async function afterDisplayAd(callback: any) {
    // callback()
    // const calls = (await store.get('calls', 0)) + 1
    // await store.set('calls', calls === 4 ? 0 : calls)

    // if (calls === 2) {
    displayInterstitial(callback)
    // } else {
    //   callback()
    // }
  }

  function onCall(preferences: State['preferences']) {
    actions.savePreferences(preferences).then(dispatch)
    afterDisplayAd(phone.connect)
  }

  function onNextCall() {
    afterDisplayAd(phone.nextCall)
  }

  return state.initialized ? (
    <Box flex={1} background="white">
      <Box safeArea flex={1}>
        <Navigator />
        <Box flexDirection="row" flex={1}>
          <Box flex="1">
            <Box bg="gray.50" px={6} py={[6, 6, 8]}>
              <Heading as="h1" size="md" textAlign="center">
                {locale.t('phone.title')}
              </Heading>
            </Box>
            <Box flex={1} justifyContent="center">
              <Container py={5} maxW="400px" maxHeight="720px" height="100%">
                <Phone
                  preferences={state.preferences}
                  onCall={onCall}
                  onCallNext={onNextCall}
                />
              </Container>
            </Box>
          </Box>
          {Platform.OS === 'web' && (
            <Box
              width="412px"
              display={['none', 'none', 'flex']}
              borderLeftWidth="1px"
              borderLeftColor="gray.200"
            >
              <Chat
                messages={state.messages}
                onSendMessage={phone.sendMessage}
                canSendMessage={
                  phone.status === PHONE_STATUSES.CALL_ESTABLISHED
                }
                onClearMessages={() => dispatch(actions.clearMessages())}
              />
            </Box>
          )}
        </Box>

        <Loader isOpen={showingAd} />
      </Box>
    </Box>
  ) : null
}
