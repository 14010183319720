import React, { useEffect, useReducer, useContext } from 'react'
import store from '../../utils/store'
import * as actions from './actions'
import reducer from './reducer'
import { State } from '../../types'

export const defaultState: State = {
  preferences: {
    nickname: undefined,
    gender: undefined,
    level: 'beginner',
    partnerGender: ''
  },
  messages: [],
  unreadMessages: false,
  initialized: false,
  settings: {
    language: 'en'
  }
}

function getInitialState(initialState: State): State {
  return {
    ...defaultState,
    ...(initialState as {})
  }
}

export const StoreContext = React.createContext({})
export const DispatchContext = React.createContext<any>(null)

type StoreProviderProps = {
  initialState: Partial<State>
  children: React.ReactNode
}

export function StoreProvider({ initialState, children }: StoreProviderProps) {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as State,
    getInitialState
  )
  useEffect(() => {
    async function setInitialData() {
      const preferences = await store.get('preferences', {})
      const messages = await store.get('messages', [])
      const unreadMessages = await store.get('unreadMessages', false)
      const language = await store.get('language', undefined)
      dispatch(
        actions.initialize({
          preferences,
          messages,
          unreadMessages,
          settings: { language }
        })
      )
    }
    setInitialData()
  }, [])
  return (
    <StoreContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {state.initialized ? children : null}
      </DispatchContext.Provider>
    </StoreContext.Provider>
  )
}

export function useStore() {
  return {
    state: useContext(StoreContext) as State,
    dispatch: useContext(DispatchContext)
  }
}
