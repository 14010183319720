import React from 'react'
import { Box } from 'native-base'
import { Platform, TouchableWithoutFeedback, View } from 'react-native'
import ReactNativeModal from 'react-native-modal'

type ModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  children: React.ReactNode
}

export default function Modal({
  isOpen,
  onRequestClose,
  children,
  ...rest
}: ModalProps) {
  return (
    <ReactNativeModal
      coverScreen={Platform.OS === 'android' ? true : false}
      useNativeDriver={true}
      isVisible={isOpen}
      animationIn={Platform.OS === 'web' ? 'slideInRight' : 'slideInUp'}
      animationOut={Platform.OS === 'web' ? 'slideOutRight' : 'slideOutDown'}
      customBackdrop={
        <TouchableWithoutFeedback
          onPress={onRequestClose}
          style={{ width: '100%' }}
        >
          <View
            // @ts-ignore
            style={{
              flex: 1,
              width: '100%',
              height: '100%',
              backgroundColor: '#000',
              position: Platform.OS === 'web' ? 'fixed' : 'absolute'
            }}
          />
        </TouchableWithoutFeedback>
      }
      style={{ margin: 0, alignItems: 'flex-end' }}
      {...rest}
    >
      <Box safeArea bg="white" flex={1} width={['100%', '100%', '420px']}>
        {children}
      </Box>
    </ReactNativeModal>
  )
}
