import React, { useContext, useState, useRef, useEffect } from 'react'
import CryptoJS from 'crypto-js/'
import { PHONE_ERRORS, PHONE_STATUSES } from './constants'
import { Base64 } from 'js-base64'
import * as actions from '../store/actions'
import { useStore } from '../store'
import { Partner } from '../../types'
import Peer, {
  DataConnection,
  getMediaDevices,
  stopTracks,
  setSpeakerMute,
  setMicrophoneMute,
  startCallManager,
  endCallManager
} from '../../utils/peer'
import useHeadphone from '../../hooks/useHeadphone'
import config from '../../config/'
import { Platform } from 'react-native'

type PhoneContextProps = {
  status: string
  isSpeakerMute: boolean
  isMicrophoneMute: boolean
  remoteStream?: MediaStream
  localStream?: MediaStream
  partner?: Partner
  error?: keyof typeof PHONE_ERRORS
  callLength: number
  connect: () => void
  endCall: () => void
  nextCall: () => void
  reset: () => void
  toggleMicrophone: () => void
  toggleSpeaker: () => void
  sendMessage: (message: string) => void
  setAudioElement: (element: HTMLAudioElement) => void
  setCallLength: (value: number) => void
  partnertNotFound: () => void
  updateCallLength: (value: number) => void
}

const PhoneContext = React.createContext<PhoneContextProps>({
  status: PHONE_STATUSES.IDLE,
  isMicrophoneMute: false,
  isSpeakerMute: false,
  callLength: 0,
  error: undefined,
  connect: () => {},
  endCall: () => {},
  nextCall: () => {},
  reset: () => {},
  toggleMicrophone: () => {},
  toggleSpeaker: () => {},
  sendMessage: (message: string) => {},
  setAudioElement: (element) => {},
  setCallLength: (value) => {},
  partnertNotFound: () => {},
  updateCallLength: (value: number) => {}
})

type PhoneProviderProps = {
  children: React.ReactNode
}

export const PhoneProvider = ({ children }: PhoneProviderProps) => {
  const peer = useRef<Peer>()
  const socket = useRef<WebSocket>()
  const conn = useRef<DataConnection>()
  const { state, dispatch } = useStore()
  const { isHeadphoneConnected } = useHeadphone()
  const audioRef = useRef<HTMLAudioElement>()

  const [error, setError] = useState<keyof typeof PHONE_ERRORS | undefined>(
    undefined
  )

  const [callLength, setCallLength] = useState(0)
  const refCallLength = useRef(callLength)

  const [isSpeakerMute, setIsSpeakerMute] = useState(Platform.OS !== 'web')

  const [isMicrophoneMute, setIsMicrophoneMute] = useState(false)

  const refPreferences = useRef(state.preferences)

  const [localStream, setLocalStream] = useState<MediaStream>()
  const refLocalStream = useRef(localStream)

  const [remoteStream, setRemoteStream] = useState<MediaStream>()
  const refRemoteStream = useRef(remoteStream)

  const [peerId, setPeerId] = useState<string>()
  const refPeerId = useRef(peerId)

  const [partner, setPartner] = useState<Partner>()
  const refPartner = useRef(partner)

  const [status, setStatus] = useState<keyof typeof PHONE_STATUSES>(
    PHONE_STATUSES.IDLE
  )

  useEffect(() => {
    if (Platform.OS !== 'web' && isHeadphoneConnected !== undefined) {
      setIsSpeakerMute(!isHeadphoneConnected)
      setSpeakerMute(audioRef.current, !isHeadphoneConnected)
    }
  }, [isHeadphoneConnected])

  useEffect(() => {
    refPreferences.current = state.preferences
  }, [state.preferences])

  function setAudioElement(element: HTMLAudioElement) {
    audioRef.current = element
  }

  function connect() {
    setStatus(PHONE_STATUSES.CONNECTING)
    fetch(`${config.API_URL}/token`, { method: 'POST' })
      .then((response) => response.text())
      .then((data) => {
        const servers = JSON.parse(
          //@ts-ignore
          CryptoJS.AES.decrypt(data, process.env.TOKEN_SATL).toString(
            CryptoJS.enc.Utf8
          )
        )
        peer.current = new Peer(undefined, {
          config: { iceServers: servers }
        })

        peer.current.on('open', (id) => {
          setPeerId(() => {
            refPeerId.current = id
            return id
          })
          getMediaDevices()
            .then((stream: any) => {
              setLocalStream(() => {
                refLocalStream.current = stream
                return stream
              })
              connectToCallServer()
              peer.current?.on('call', (call) => {
                peer.current?.connect(call.peer)
                call.answer(refLocalStream.current!)
                call.on('error', (error) => {
                  console.log('error: receiving call', error.type)
                })
                call.on('stream', onReceiveRemoteStream)
              })
            })
            .catch((err) => {
              setError(PHONE_ERRORS.PERMISSIONS)
              setStatus(PHONE_STATUSES.IDLE)
            })
        })

        peer.current.on('disconnect', endCall)
        peer.current.on('error', endCall)
      })
      .catch((err) => {
        console.log('ERROR', err)
      })
  }

  function connectToCallServer() {
    socket.current = new WebSocket(config.WS_URL)
    socket.current.addEventListener('open', onConnectToCallServer)

    // Listen for messages
    socket.current.addEventListener('message', function (event) {
      const { action, body: data } = JSON.parse(event.data)

      if (action === 'call') {
        setPartner(() => {
          refPartner.current = data
          return data
        })

        if (data.receiver === false) {
          const call = peer.current?.call(data.peerId, refLocalStream.current!)
          call?.on('error', (error) => {
            console.log('error: calling', error.type)
          })
          call?.on('stream', onReceiveRemoteStream)
        }
      }

      if (action === 'hangUp') {
        endCall()
      }
    })
  }

  function onConnectToCallServer() {
    startCallManager()
    setStatus(PHONE_STATUSES.CONNECTED)
    socket.current?.send(
      JSON.stringify({
        action: 'join',
        user: {
          peerId: refPeerId.current,
          ...refPreferences.current
        }
      })
    )
  }

  function onReceiveRemoteStream(stream: MediaStream) {
    setRemoteStream(() => {
      refRemoteStream.current = stream
      return stream
    })
    if (audioRef.current && Platform.OS === 'web') {
      audioRef.current.srcObject = stream
      audioRef.current.addEventListener('loadedmetadata', audioRef.current.play)
    }
    peer.current?.on('connection', onConnectToMessageLayer)
    conn.current = peer.current?.connect(refPartner.current?.peerId!)
    conn.current?.on('open', onConnectToMessageLayer)
    setStatus(PHONE_STATUSES.CALL_ESTABLISHED)
  }

  function onConnectToMessageLayer(connection?: DataConnection) {
    conn.current = connection ? connection : conn.current
    conn.current?.on('data', onReceiveMessage)
  }

  function endCall() {
    finishCall()
    setStatus(PHONE_STATUSES.HUNG_UP)
    setStatus(
      refCallLength.current > 0 ? PHONE_STATUSES.HUNG_UP : PHONE_STATUSES.IDLE
    )
  }

  function nextCall() {
    finishCall()
    connect()
  }

  function reset() {
    updateCallLength(0)
    setError(PHONE_ERRORS.NONE)
    setStatus(PHONE_STATUSES.IDLE)
  }

  function finishCall() {
    socket.current?.close()
    peer.current?.destroy()
    setIsMicrophoneMute(false)
    setMicrophoneMute(localStream, false)
    stopTracks(refLocalStream.current)
    setLocalStream(() => {
      refLocalStream.current = undefined
      return undefined
    })
    setRemoteStream(() => {
      refRemoteStream.current = undefined
      return undefined
    })
    endCallManager()
  }

  function toggleMicrophone() {
    setMicrophoneMute(localStream, !isMicrophoneMute)
    setIsMicrophoneMute(!isMicrophoneMute)
  }

  function toggleSpeaker() {
    setSpeakerMute(audioRef.current, !isSpeakerMute)
    setIsSpeakerMute(!isSpeakerMute)
  }

  function partnertNotFound() {
    finishCall()
    setStatus(PHONE_STATUSES.IDLE)
    setError(PHONE_ERRORS.PARTNER_NOT_FOUND)
  }

  function sendMessage(message: string) {
    const formatted = formatMessage(message)
    conn.current?.send(Base64.encode(formatted))
    actions
      .sendMessage({
        user: refPreferences.current.nickname!,
        timestamp: Date.now(),
        fromClient: true,
        message: formatted
      })
      .then(dispatch)
  }

  function onReceiveMessage(message: string) {
    actions
      .receiveMessage({
        user: refPartner.current?.nickname!,
        timestamp: Date.now(),
        message: formatMessage(Base64.decode(message))
      })
      .then(dispatch)
  }

  function updateCallLength(value: number) {
    setCallLength(value)
    refCallLength.current = value
  }

  return (
    <PhoneContext.Provider
      value={{
        status,
        isMicrophoneMute,
        isSpeakerMute,
        callLength,
        partner,
        error,
        connect,
        nextCall,
        endCall,
        reset,
        toggleMicrophone,
        toggleSpeaker,
        sendMessage,
        remoteStream,
        setAudioElement,
        setCallLength,
        partnertNotFound,
        updateCallLength
      }}
    >
      {children}
    </PhoneContext.Provider>
  )
}

export function usePhone() {
  return useContext(PhoneContext)
}

// utils
function formatMessage(string: string) {
  return string.replace(/\n\n*/g, '\r\n')
}
