const development = {
  WS_URL: 'wss://2gtxaxp2al.execute-api.us-east-1.amazonaws.com/dev',
  API_URL: 'https://hltyemqrwa.execute-api.us-east-1.amazonaws.com'
}
const production = {
  WS_URL: 'wss://11ik4p7kmb.execute-api.us-east-1.amazonaws.com/prod',
  API_URL: 'https://p706yjxid6.execute-api.us-east-1.amazonaws.com'
}

export default __DEV__ ? development : production
