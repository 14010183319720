import Header, { HeaderTitle, HeaderPressable } from './Header'
import { Box, FormControl, Stack, Select } from 'native-base'
import Icon from './Icon'
import useLocale from '../context/locale'
import { useStore } from '../context/store'
import * as actions from '../context/store/actions'
import Container from './Container'

type SettingsProps = {
  onRequestClose: () => void
}

export default function Settings({ onRequestClose }: SettingsProps) {
  const { state, dispatch } = useStore()
  const locale = useLocale()

  function onChangeLanguage(value: string) {
    actions.changeLanguage(value).then(dispatch)
  }

  return (
    <Box>
      <Header>
        <HeaderPressable onPress={onRequestClose}>
          <Icon icon={['far', 'arrow-left']} size={22} />
        </HeaderPressable>
        <HeaderTitle>{locale.t('settings.title')}</HeaderTitle>
      </Header>
      <Container my={5}>
        <FormControl>
          <Stack>
            <FormControl.Label>
              {locale.t('settings.language.label')}
            </FormControl.Label>
            <Select
              bg="white"
              selectedValue={state.settings.language}
              onValueChange={onChangeLanguage}
              accessibilityLabel={locale.t('language')}
              placeholder={locale.t('language')}
            >
              <Select.Item label={locale.t('english')} value="en" />
              <Select.Item label={locale.t('spanish')} value="es" />
            </Select>
          </Stack>
        </FormControl>
      </Container>
    </Box>
  )
}
