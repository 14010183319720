import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import Modal from './Modal'
import { Box } from 'native-base'
import { useRoute, useNavigation } from '@react-navigation/native'
import * as actions from '../context/store/actions'
import { useStore } from '../context/store'
import { usePhone } from '../context/phone'
import { PHONE_STATUSES } from '../context/phone/constants'
import Header, { HeaderTitle, HeaderItem, HeaderPressable } from './Header'
import Icon from './Icon'
import Chat from './Chat'
import useLocale from '../context/locale'
import Settings from './Settings'
//@ts-ignore
import Logo from '../../assets/logo.svg'

export default function Navigator() {
  const phone = usePhone()
  const navigation = useNavigation()
  // const { name } = useRoute()
  const { state, dispatch } = useStore()
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  useEffect(() => {
    if (isChatOpen === true) {
      actions.readMessages().then(dispatch)
    }
  }, [state.messages, isChatOpen])
  return (
    <>
      <Header>
        {/* {name === 'Phone' && (
          <HeaderPressable
            onPress={() => {
              phone.endCall()
              phone.reset()
              navigation.navigate('Home')
            }}
          >
            <Icon icon={['far', 'arrow-left']} size={22} />
          </HeaderPressable>
        )} */}

        <HeaderItem mr="auto">
          <Logo width={126} />
        </HeaderItem>

        {/* <HeaderTitle mr="auto">
          {Platform.OS !== 'web' && name === 'Phone' ? 'Talk' : 'Talklearn'}
        </HeaderTitle> */}

        <HeaderPressable
          onPress={() => setIsChatOpen(true)}
          display={['flex', 'flex', 'none']}
        >
          <Icon icon={['far', 'comment-alt-dots']} size={20} />
          {state.unreadMessages && (
            <Box
              width="12px"
              height="12px"
              borderRadius="999px"
              background="primary.500"
              position="absolute"
              right="8px"
              top="8px"
            />
          )}
        </HeaderPressable>

        <HeaderPressable onPress={() => setIsSettingsOpen(true)}>
          <Icon icon={['far', 'sliders-h']} size={20} />
        </HeaderPressable>
      </Header>

      <Modal isOpen={isChatOpen} onRequestClose={() => setIsChatOpen(false)}>
        <Chat
          messages={state.messages}
          onSendMessage={phone.sendMessage}
          canSendMessage={phone.status === PHONE_STATUSES.CALL_ESTABLISHED}
          onRequestClose={() => setIsChatOpen(false)}
          onClearMessages={() => dispatch(actions.clearMessages())}
        />
      </Modal>

      <Modal
        isOpen={isSettingsOpen}
        onRequestClose={() => setIsSettingsOpen(false)}
      >
        <Settings onRequestClose={() => setIsSettingsOpen(false)} />
      </Modal>
    </>
  )
}
