import { Platform, NativeModules } from 'react-native'
import { APP_LANGUAGES } from './../app.constants'

export function getDefaultLanguage() {
  const deviceLanguage: string = (
    Platform.OS === 'ios'
      ? NativeModules.SettingsManager.settings.AppleLocale ||
        NativeModules.SettingsManager.settings.AppleLanguages[0] //iOS 13
      : Platform.OS === 'android'
      ? NativeModules.I18nManager.localeIdentifier
      : 'en'
  ).slice(0, 2)

  return deviceLanguage in APP_LANGUAGES ? deviceLanguage : 'en'
}
