import React from 'react'
import Peer, { DataConnection } from 'peerjs'

function getMediaDevices() {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(resolve)
      .catch(reject)
  })
}

function stopTracks(stream: MediaStream) {
  if (stream) {
    stream.getTracks().forEach((element) => {
      element.stop()
    })
  }
}

function setSpeakerMute(element?: any, mute?: boolean) {
  element.muted = mute
}

function setMicrophoneMute(stream: any, mute?: boolean) {
  stream?.getTracks().forEach((element: any) => {
    element.enabled = !mute
  })
}

const AudioElement = React.forwardRef((props: any, ref) => {
  return <audio ref={ref} {...props} />
})

function startCallManager() {
  return null
}

function endCallManager() {
  return null
}

export default Peer
export {
  DataConnection,
  getMediaDevices,
  stopTracks,
  setSpeakerMute,
  setMicrophoneMute,
  AudioElement,
  startCallManager,
  endCallManager
}
