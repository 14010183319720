export const VIEWS = {
  WELCOME: 'WELCOME',
  CALL: 'CALL'
} as const

export const CALL_STATUSES = {
  CONNECTED: 'CONNECTED',
  END: 'END'
} as const

export const APP_LANGUAGES = {
  es: 'es',
  en: 'en'
}
