import React from 'react'
import { useToken } from 'native-base'

import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faTimes as fasTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faRandom as fasRandom } from '@fortawesome/pro-solid-svg-icons/faRandom'
import { faVolume as fasVolume } from '@fortawesome/pro-solid-svg-icons/faVolume'
import { faVolumeSlash as fasVolumeSlash } from '@fortawesome/pro-solid-svg-icons/faVolumeSlash'
import { faMicrophone as fasMicrophone } from '@fortawesome/pro-solid-svg-icons/faMicrophone'
import { faMicrophoneSlash as fasMicrophoneSlash } from '@fortawesome/pro-solid-svg-icons/faMicrophoneSlash'

import { faArrowLeft as fasArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faPlusCircle as fasPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faCog as fasCog } from '@fortawesome/free-solid-svg-icons/faCog'

import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faRandom as farRandom } from '@fortawesome/pro-regular-svg-icons/faRandom'
import { faCommentAltDots as farCommentAltDots } from '@fortawesome/pro-regular-svg-icons/faCommentAltDots'
import { faSlidersH as farSlidersH } from '@fortawesome/pro-regular-svg-icons/faSlidersH'
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'

import { faVenus as farVenus } from '@fortawesome/pro-regular-svg-icons/faVenus'
import { faMars as farMars } from '@fortawesome/pro-regular-svg-icons/faMars'
import { faEllipsisV as farEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'

import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faPhone as falPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faRandom as falRandom } from '@fortawesome/pro-light-svg-icons/faRandom'
import { faVolume as falVolume } from '@fortawesome/pro-light-svg-icons/faVolume'
import { faVolumeSlash as falVolumeSlash } from '@fortawesome/pro-light-svg-icons/faVolumeSlash'
import { faMicrophone as falMicrophone } from '@fortawesome/pro-light-svg-icons/faMicrophone'
import { faMicrophoneSlash as falMicrophoneSlash } from '@fortawesome/pro-light-svg-icons/faMicrophoneSlash'

import { faGoogle as fabGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  Props as FontAwesomeIconProps
} from '@fortawesome/react-native-fontawesome'

library.add(
  // solid
  fasTimes,
  fasPhone,
  fasRandom,
  fasVolume,
  fasVolumeSlash,
  fasMicrophone,
  fasMicrophoneSlash,
  fasArrowLeft,
  fasPlusCircle,
  fasCog,

  // regular
  farTimes,
  farArrowLeft,
  farRandom,
  farCommentAltDots,
  farSlidersH,
  farAngleLeft,
  farVenus,
  farMars,
  farEllipsisV,

  // light
  falTimes,
  falPhone,
  falRandom,
  falVolume,
  falVolumeSlash,
  falMicrophone,
  falMicrophoneSlash,

  // brands
  fabGoogle
)

type IconProps = FontAwesomeIconProps & {
  color?: string | number
}

const Icon = ({ color = 'black', ...rest }: IconProps) => {
  const [colorCode] = useToken('colors', [color])
  return <FontAwesomeIcon size={16} color={colorCode} {...rest} />
}

export default Icon
