import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import {
  Box,
  Heading,
  HStack,
  Pressable,
  IPressableProps,
  IBoxProps,
  IHeadingProps
} from 'native-base'
import Container from './Container'

export const HeaderPressable = ({
  children,
  ...rest
}: {
  children: React.ReactNode
} & IPressableProps) => {
  return (
    <Pressable p={2} {...rest}>
      {children}
    </Pressable>
  )
}

export const HeaderItem = ({
  children,
  ...rest
}: {
  children: React.ReactNode
} & IBoxProps) => {
  return (
    <Box p={3} {...rest}>
      {children}
    </Box>
  )
}

export const HeaderTitle = ({
  children,
  ...rest
}: {
  children: React.ReactNode
} & IHeadingProps) => {
  return (
    <Heading size={Platform.OS === 'web' ? 'md' : 'lg'} px={3} {...rest}>
      {children}
    </Heading>
  )
}

const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      height="68px"
      bg="white"
      zIndex={1}
      shadow={['none', 'none', 2]}
      justifyContent="center"
    >
      <Container maxW={null}>
        <Box flexDirection="row" alignItems="center" mx={-3}>
          {children}
        </Box>
      </Container>
    </Box>
  )
}

export default Header
