import React from 'react'
import theme from './src/theme'
import { StatusBar, NativeBaseProvider } from 'native-base'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Home, Call } from './src/screens'
import { LocaleProvider } from './src/context/locale'
import { PhoneProvider } from './src/context/phone'
import { StoreProvider } from './src/context/store/index'
import { getDefaultLanguage } from './src/utils'
import phrases from './src/config/locale'

const Stack = createNativeStackNavigator()

export default function App() {
  return (
    <NavigationContainer documentTitle={{ enabled: false }}>
      <NativeBaseProvider theme={theme}>
        <StoreProvider
          initialState={{ settings: { language: getDefaultLanguage() } }}
        >
          <LocaleProvider phrases={phrases}>
            <PhoneProvider>
              <StatusBar translucent={false} barStyle="dark-content" />
              <Call />
              {/* <Stack.Navigator screenOptions={{ headerShown: false }}>
                <Stack.Screen name="Phone" component={Call} />
              </Stack.Navigator> */}
            </PhoneProvider>
          </LocaleProvider>
        </StoreProvider>
      </NativeBaseProvider>
    </NavigationContainer>
  )
}
