import { extendTheme } from 'native-base'

const extendedTheme = extendTheme({})

const theme = extendTheme({
  components: {
    Button: {
      defaultProps: {
        px: 6,
        py: 3,
        fontSize: '16px',
        borderRadius: 'md'
      }
    },
    Input: {
      defaultProps: {
        px: 4,
        py: 3,
        fontSize: '16px',
        borderRadius: 'md'
      }
    },
    Select: {
      defaultProps: {
        px: 4,
        py: 3,
        fontSize: '16px',
        borderRadius: 'md'
      }
    },
    Text: {
      defaultProps: {
        color: 'text.normal'
      }
    },
    Heading: {
      defaultProps: {
        lineHeight: 'md'
      }
    }
  },
  colors: {
    primary: {
      50: '#eef2ff',
      100: '#e0e7ff',
      200: '#c7d2fe',
      300: '#a5b4fc',
      400: '#818cf8',
      500: '#4f46e5',
      600: '#4338ca',
      700: '#3730a3',
      800: '#312e81',
      900: '#312e81'
    },
    text: {
      normal: extendedTheme.colors.dark['50']
    }
  },
  breakpoints: {
    '2xl': 1920,
    base: 0,
    sm: 768, // tablet
    md: 992, // laptop
    lg: 1280, // desktop
    xl: 1536
  }
})

export default theme
